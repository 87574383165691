import { createContext, useMemo, ReactNode } from "react";
import validator from "validator";
import { ActiveFlowContainerQuery, FlowNodeType } from "~/graphql/api/sdk";

export type Flow = NonNullable<ActiveFlowContainerQuery["activeFlowContainer"]>["flow"];

interface ProviderProps {
  children: ReactNode;
  flow: Flow;
  slug?: string;
}

interface ContextProps {
  flow: Flow;
  hasConditionalLogic: boolean;
  hasWelcomeScreen: boolean;
  hasEmailStep: boolean;
  totalQuestions: number;
  totalQuestionsForDisplay?: number;
  emailNodeId?: string;
  slug?: string;
}

export const FlowContext = createContext<ContextProps>({} as ContextProps);

export const FlowProvider = ({ children, flow, slug }: ProviderProps) => {
  const hasWelcomeScreen = useMemo(() => flow?.nodes?.[0]?.type === FlowNodeType.Welcome, [flow]);
  const hasEmailStep = useMemo(() => flow?.nodes?.[flow?.nodes?.length - 1]?.type === FlowNodeType.Email, [flow]);
  const hasConditionalLogic = useMemo(() => {
    let startIndex = 0;
    let distances;
    const totalNodes = flow.nodes?.length;
    while (startIndex < totalNodes && !distances) {
      distances = flow.nodes?.[startIndex]?.distanceToEnd ?? undefined;
      startIndex++;
    }

    return !!distances && distances.max !== distances.min;
  }, [flow, hasWelcomeScreen]);

  const emailNodeId = useMemo(() => flow?.nodes?.find((node) => node.type === FlowNodeType.Email)?.id, [flow]);

  // total questions for display purposes (excluding welcome, email and transition)
  // e.g. "Question 1 of 3"
  const totalQuestionsForDisplay = flow?.nodes?.filter(
    (node) => node.type !== FlowNodeType.Welcome && node.type !== FlowNodeType.Transition && node.type !== FlowNodeType.Email
  )?.length;

  const totalQuestions = useMemo(() => flow?.nodes?.filter((node) => node.type !== FlowNodeType.Welcome)?.length, [flow]);

  //Due to issues with some colors being stored without a prefix of "#" we need to update it
  //This can be remove in the future
  if (flow.primaryColor?.[0] !== "#") {
    const newPrimaryColor = "#" + flow.primaryColor;

    //Confirm that newPrimaryColor is still a hexColor with an added prefix
    if (validator.isHexColor(newPrimaryColor)) {
      flow.primaryColor = newPrimaryColor;
    }
  }

  return (
    <FlowContext.Provider
      value={{
        flow,
        hasConditionalLogic,
        hasEmailStep,
        hasWelcomeScreen,
        totalQuestions,
        totalQuestionsForDisplay,
        emailNodeId,
        slug,
      }}
    >
      {children}
    </FlowContext.Provider>
  );
};
