import { Text } from "@storyofams/react-ui";
import styled from "styled-components";

export const Title = styled(Text).attrs((attrs) => ({
  as: "h2",
  color: "black90",
  fontSize: [3, 4],
  fontWeight: "bold",
  lineHeight: ["29px", "39px"],
  ...attrs,
}))``;
