import { FileUnion } from '~/graphql/api/sdk';

export const getFileUrl = (file?: FileUnion) => {
  if (!file) {
    return '';
  }

  if ('url' in file) {
    return file.url;
  }

  return `${process.env.REACT_APP_UPLOADS_FOLDER}/${file.directory}/${file.fileName}`;
};
