import { getSdk } from '~/graphql/api/client';

const updateOptions = (options, shop: string, token?: string) => ({
  ...options,
  headers: {
    ...(options.headers || {}),
    ...(token
      ? { Authorization: `Bearer ${token}` }
      : { 'X-PPF-Client-Shopify-Id': shop }),
  },
});

const cookieFetch = (shop: string, token?: string) => (url, options) =>
  fetch(url, updateOptions(options, shop, token));

export const useSdk = () => {
  const token = (window as any)?.__ppfShopifyJwt;

  return getSdk(cookieFetch((window as any).__ppfShopifyId, token));
};
