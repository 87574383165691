import { useMemo } from "react";
import { useLocation } from "react-router";

import { useFlow, useSession } from "~/hooks";

export const useProgress = () => {
  const { flow, hasConditionalLogic, hasEmailStep, hasWelcomeScreen, totalQuestions } = useFlow();
  const { pathname } = useLocation();
  const { session } = useSession();

  const number = pathname.match(/^\/questions\/(.*)/i)?.[1];
  const current = number ? parseInt(number, 10) : undefined || 0;

  //Create an array of unique nodeId answers. (filter multiple choices on one node)
  const uniqueSelections: any = [];
  session?.selections.forEach((selection) => {
    if (!uniqueSelections.find((item) => item?.node.id === selection.node.id)) {
      if (flow.nodes.findIndex(({ id }) => id === selection.node.id) < current) {
        uniqueSelections.push(selection);
      }
    }
  });

  const question = useMemo(() => flow.nodes?.[current - (hasWelcomeScreen ? 0 : 1)], [flow, hasWelcomeScreen, current]);

  const [currentQuestionNumber, total] = useMemo(() => {
    if (!hasConditionalLogic) {
      return [current, totalQuestions];
    }

    //Find the index of the current question
    const currentSelectionIdx = uniqueSelections.findIndex(({ node: { id } }) => id === question?.id);

    const selectedUptoThisQuestion = (currentSelectionIdx !== -1 ? uniqueSelections.slice(0, currentSelectionIdx) : uniqueSelections)?.length || 0;

    return [selectedUptoThisQuestion + 1, selectedUptoThisQuestion + (question?.distanceToEnd?.max || 0) + (hasEmailStep ? 1 : 0)];
  }, [current, hasConditionalLogic, hasEmailStep, session, question, totalQuestions, pathname]);

  return { currentQuestionNumber, total };
};
