import { shouldPolyfill as shouldPolyfillGetCanonicalLocales } from '@formatjs/intl-getcanonicallocales/should-polyfill';
import { shouldPolyfill as shouldPolyfillLocale } from '@formatjs/intl-locale/should-polyfill';
import { shouldPolyfill as shouldPolyfillNumberFormat } from '@formatjs/intl-numberformat/should-polyfill';
import { shouldPolyfill as shouldPolyfillPluralRules } from '@formatjs/intl-pluralrules/should-polyfill';

const safeShouldPolyfill = (shouldPolyfillTest) => {
  let shouldPolyfill = false;

  try {
    shouldPolyfill = shouldPolyfillTest();
  } catch (e) {
    shouldPolyfill = true;
  }

  return shouldPolyfill;
};

/**
 * Dynamically polyfill Intl API & its locale data
 * @param locale locale to polyfill
 */
export const polyfill = async (locale: string = '') => {
  const dataPolyfills: any[] = [];

  // Polyfill Intl.getCanonicalLocales if necessary
  if (shouldPolyfillGetCanonicalLocales()) {
    await import(
      /* webpackChunkName: "intl-getcanonicallocales" */ '@formatjs/intl-getcanonicallocales/polyfill'
    );
  }

  // Polyfill Intl.Locale if necessary
  if (shouldPolyfillLocale()) {
    await import(
      /* webpackChunkName: "intl-locale" */ '@formatjs/intl-locale/polyfill'
    );
  }

  // Polyfill Intl.PluralRules if necessary
  if (safeShouldPolyfill(shouldPolyfillPluralRules)) {
    await import(
      /* webpackChunkName: "intl-pluralrules" */ '@formatjs/intl-pluralrules/polyfill'
    );
  }

  if ((Intl.PluralRules as any).polyfilled) {
    dataPolyfills.push(
      import(
        /* webpackChunkName: "intl-pluralrules" */ `@formatjs/intl-pluralrules/locale-data/${locale}`
      ),
    );
  }

  // Polyfill Intl.NumberFormat if necessary
  if (safeShouldPolyfill(shouldPolyfillNumberFormat)) {
    await import(
      /* webpackChunkName: "intl-numberformat" */ '@formatjs/intl-numberformat/polyfill'
    );
  }

  if ((Intl.NumberFormat as any).polyfilled) {
    dataPolyfills.push(
      import(
        /* webpackChunkName: "intl-numberformat" */ `@formatjs/intl-numberformat/locale-data/${locale}`
      ),
    );
  }

  await Promise.all(dataPolyfills);
};
