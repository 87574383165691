import { FC } from "react";
import { LazyMotion } from "framer-motion";
import { HeadProvider } from "react-head";
import { IntlProvider } from "react-intl";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";

import { RtlProvider } from "~/context";
import theme from "~/styles/theme";

const loadFeatures = () => import("./motionFeatures").then((res) => res.default);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
    },
  },
});

interface ProvidersProps {
  isRtl: boolean;
  locale: string;
  messages: any;
}

export const Providers: FC<ProvidersProps> = ({ children, isRtl, locale, messages }) => (
  <HeadProvider headTags={[]}>
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <IntlProvider messages={messages} defaultLocale="en" locale={locale}>
          <RtlProvider isRtl={isRtl}>
            <LazyMotion features={loadFeatures}>{children}</LazyMotion>
          </RtlProvider>
        </IntlProvider>
      </QueryClientProvider>
    </ThemeProvider>
  </HeadProvider>
);
