import { toast } from 'react-toastify';

import { Toast } from '~/components';

export const showToast = (
  message: string,
  type: 'info' | 'success' | 'error' = 'info',
) =>
  toast(({ closeToast }) => (
    <Toast message={message} type={type} onClose={closeToast} />
  ));
