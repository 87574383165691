import { GraphQLClient } from 'graphql-request';

import { getSdk as getGraphqlSdk } from './sdk';

export const getClient = (shopOrigin: string, fetch?: any) =>
  new GraphQLClient(`https://${shopOrigin}/api/2021-07/graphql.json`, {
    fetch,
  });

export const getSdk = (shopOrigin: string, fetch?: any) =>
  getGraphqlSdk(getClient(shopOrigin, fetch));
