import { Breakpoints } from '../styled';

import { space } from './space';

const theme = {
  colors: {
    primary: '#0D34FF',
    black90: 'rgba(0, 0, 0, 0.9)',
    black80: 'rgba(0, 0, 0, 0.8)',
    black60: 'rgba(0, 0, 0, 0.6)',
    black40: 'rgba(0, 0, 0, 0.4)',
    black20: 'rgba(0, 0, 0, 0.2)',
    black10: 'rgba(0, 0, 0, 0.1)',
    black4: 'rgba(0, 0, 0, 0.04)',
    white90: 'rgba(255, 255, 255, 0.9)',
    white80: 'rgba(255, 255, 255, 0.8)',
    white60: 'rgba(255, 255, 255, 0.6)',
    white40: 'rgba(255, 255, 255, 0.4)',
    white20: 'rgba(255, 255, 255, 0.2)',
    white10: 'rgba(255, 255, 255, 0.1)',
    white4: 'rgba(255, 255, 255, 0.04)',
    white: '#FFF',
    error: '#EB0303',
    errorText: '#B70404',
  },
  fontWeights: {
    regular: 400,
    bold: 700,
  },
  fontSizes: {
    1.25: space['1.25'],
    1.5: space['1.5'],
    1.75: space['1.75'],
    2: space['2'],
    2.25: space['2.25'],
    2.5: space['2.5'],
    3: space['3'],
    4: space['4'],
    5: space['5'],
    6: space['6'],
    7: space['7'],
    8: space['8'],
    10: space['9'],
    20: space['20'],
    root: space['1.75'],
    heading: space['4'],
  },
  lineHeights: {
    normal: 1,
    heading: 1.1,
    medium: 1.25,
    high: 1.6,
  },
  space: {
    ...space,
  },
  sizes: {
    maxWidth: 928,
  },
  breakpoints: ['768px', '992px', '1172px', '1400px'] as Breakpoints,
  zIndices: {
    hide: -1,
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    skipLink: 1600,
    toast: 1700,
    tooltip: 1800,
  },
  radii: {
    none: '0',
    xs: '4px',
    sm: '6px',
    md: '8px',
    lg: '16px',
    full: '9999px',
  },
  borders: {
    none: 0,
    '1px': '1px solid',
    '2px': '2px solid',
    '4px': '4px solid',
  },
  shadows: {
    sm: '0px 2px 0px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.05)',
    md: '0px 2px 0px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.05)',
    lg: '0px 2px 4px rgba(0, 0, 0, 0.1), 0px 10px 20px rgba(0, 0, 0, 0.1)',
    none: 'none',
  },
};

theme.breakpoints.xs = '576px';
theme.breakpoints.sm = theme.breakpoints[0];
theme.breakpoints.md = theme.breakpoints[1];
theme.breakpoints.lg = theme.breakpoints[2];
theme.breakpoints.xl = theme.breakpoints[3];

theme.breakpoints.maxXs = '575px';
theme.breakpoints.maxSm = '767px';
theme.breakpoints.maxMd = '991px';
theme.breakpoints.maxLg = '1171px';

export default theme;
