import { Box, SystemProps } from '@storyofams/react-ui';
import styled from 'styled-components';

export const Container = styled(Box).attrs<SystemProps>((attrs) => ({
  mx: 'auto',
  px: '16px',
  width: '100%',
  maxWidth: undefined,
  ...attrs,
}))`
  max-width: 100%;

  @media screen and (min-width: ${(p) => p.maxWidth || '768px'}) {
    max-width: ${(p) => p.maxWidth || '738px'};
  }

  @media screen and (min-width: ${(p) => p.maxWidth || '1172px'}) {
    max-width: ${(p) => p.maxWidth || '1172px'};
  }
`;
