import { createContext, ReactNode, useMemo, useState, useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { AddSelectionInput, AddAnswerInput, EndSessionMutation, FlowLocale, SessionFragmentFragment } from "~/graphql/api/sdk";
import { useFlow, useHeadless, useSdk } from "~/hooks";
import { showToast } from "~/lib";

const messages = defineMessages({
  error: "An error has occurred.",
});

interface ProviderProps {
  children: ReactNode;
}

export type Session = SessionFragmentFragment & {
  status?: EndSessionMutation["endSession"]["status"];
  results?: EndSessionMutation["endSession"]["results"];
};

interface ContextProps {
  session: Session | null;
  startSession(flowId: string): Promise<boolean>;
  addSelection(input: Omit<AddSelectionInput, "sessionId">): Promise<boolean>;
  addAnswer(input: Omit<AddAnswerInput, "sessionId">): Promise<boolean>;
  endSession(email?: string): Promise<boolean>;
  restartSession(): Promise<boolean>;
  clearSession(): void;
}

export const SessionContext = createContext<ContextProps>({} as ContextProps);

export const SessionProvider = ({ children }: ProviderProps) => {
  const intl = useIntl();
  const { isHeadless, addSessionAttribute } = useHeadless();
  const history = useHistory();
  const { flow } = useFlow();
  const [session, setSession] = useState<Session | null>(null);
  const sdk = useSdk();

  const locale = useMemo(() => {
    if (/^\/(\w{2})\//i.test(window.location.pathname)) {
      return window.location.pathname.split("/")?.[1] || "en";
    }

    return (flow.locale === FlowLocale.Custom ? flow.translation?.locale : flow.locale) || "en";
  }, [window.location.pathname, flow]);

  const sessionId = useMemo(() => {
    if (/\/results\/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12})/i.test(window.location.hash)) {
      return window.location.hash.split("/")?.[2];
    }

    return null;
  }, [window.location.hash]);

  useEffect(() => {
    const runEffect = async () => {
      try {
        const input = { id: sessionId, locale };
        const result = await sdk.endSession({
          input,
          locale,
        });

        setSession(result.endSession);

        return true;
      } catch (e: any) {
        showToast(e?.message || intl.formatMessage(messages.error), "error");
        return false;
      }
    };

    if (sessionId && locale) {
      runEffect();
    }
  }, [sessionId, locale]);

  const startSession = async (flowId: string) => {
    try {
      const result = await sdk.startSession({
        input: {
          flowId,
          referrer: document.referrer,
          screenWidth: window.innerWidth,
        },
      });

      setSession(result.startSession);

      return true;
    } catch (e: any) {
      showToast(e?.message || intl.formatMessage(messages.error), "error");
      return false;
    }
  };

  const addSelection = async (input: Omit<AddSelectionInput, "sessionId">) => {
    try {
      const result = await sdk.addSelection({
        input: {
          ...input,
          sessionId: session?.id,
        },
      });

      setSession(result.addSelection);

      if (isHeadless) {
        addSessionAttribute(result.addSelection.id);
      } else if ((window as any).__ppfSetCartSession) {
        (window as any).__ppfSetCartSession(result.addSelection.id);
      }

      return true;
    } catch (e: any) {
      if (e?.response?.errors?.[0]?.message === "The session has already ended.") {
        // Go to results if session has already ended
        history.push("/results");
      } else {
        showToast(e?.message || intl.formatMessage(messages.error), "error");
      }

      return false;
    }
  };

  const addAnswer = async (input: Omit<AddAnswerInput, "sessionId">) => {
    try {
      const result = await sdk.addAnswer({
        input: {
          ...input,
          sessionId: session?.id,
        },
      });

      setSession(result.addAnswer);

      if (isHeadless) {
        addSessionAttribute(result.addAnswer.id);
      } else if ((window as any).__ppfSetCartSession) {
        (window as any).__ppfSetCartSession(result.addAnswer.id);
      }

      return true;
    } catch (e: any) {
      if (e?.response?.errors?.[0]?.message === "The session has already ended.") {
        // Go to results if session has already ended
        history.push("/results");
      } else {
        showToast(e?.message || intl.formatMessage(messages.error), "error");
      }

      return false;
    }
  };

  const endSession = async (email?: string) => {
    if (!session) {
      return false;
    }

    const input = email ? { id: session.id, email, locale } : { id: session.id, locale };

    try {
      const result = await sdk.endSession({
        input,
        locale,
      });

      setSession(result.endSession);

      return true;
    } catch (e: any) {
      showToast(e?.message || intl.formatMessage(messages.error), "error");
      return false;
    }
  };

  const restartSession = async () => {
    if (!session) {
      return false;
    }

    try {
      const result = await sdk.restartSession({ id: session.id });

      setSession(result.restartSession);

      return true;
    } catch (e) {
      return false;
    }
  };

  const clearSession = () => {
    setSession(null);
  };

  return (
    <SessionContext.Provider
      value={{
        session,
        startSession,
        addSelection,
        addAnswer,
        endSession,
        restartSession,
        clearSession,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
