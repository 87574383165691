import { getSdk } from '~/graphql/shopify/client';

const updateOptions = (options, token: string) => ({
  ...options,
  headers: {
    ...(options.headers || {}),
    'X-Shopify-Storefront-Access-Token': token,
  },
});

const cookieFetch = (token: string) => (url, options) =>
  fetch(url, updateOptions(options, token));

export const useShopifySdk = (storefrontAccessToken: string) => {
  return getSdk(
    (window as any).__ppfShopifyId,
    cookieFetch(storefrontAccessToken),
  );
};
