import { polyfill } from './polyfills';

export const loadMessages = async (locale: string) => {
  let messagePromise;

  switch (locale) {
    case 'nl':
      messagePromise = import('~/translations/nl.json');
      break;
    default:
      messagePromise = import('~/translations/en.json');
      break;
  }

  const [, messages] = await Promise.all([polyfill(locale), messagePromise]);

  return messages.default;
};
