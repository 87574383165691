import { GraphQLClient } from 'graphql-request';

import config from '~/config';
import { getSdk as getGraphqlSdk } from './sdk';

export const getClient = (fetch?: any) =>
  new GraphQLClient(config.graphqlUrl, {
    fetch,
  });

export const getSdk = (fetch?: any) => getGraphqlSdk(getClient(fetch));
