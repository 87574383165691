const formatStringToCamelCase = (str) => {
  const splitted = str.split("-");
  if (splitted.length === 1) return splitted[0];
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join("")
  );
};

export const convertCSStoObject = (cssString) => {
  let cssObject = {};

  // remove !important (as it is not supported in JS)
  cssString = cssString?.replace(/!important/g, "");

  //1. Split the string into classes
  const classes = cssString?.split("}");

  //2. Generate an object property for each class
  classes?.forEach((c) => {
    let name = c.split("{")[0].trim();
    let propertiesString = c.split("{")[1];

    //3. Use regex to parse the string of css properties and convert it to a JS object
    let propertiesObject = {};
    var match;
    var regex = /([\w-]*)\s*:\s*([^;]*)/g;
    while ((match = regex.exec(propertiesString))) {
      const property = formatStringToCamelCase(match[1].trim());
      const value = match[2].trim();
      propertiesObject[property] = value;
    }

    cssObject[name] = propertiesObject;
  });

  return cssObject;
};

export const replaceVariables = (string, variables) => {
  // variables is an object with key value pairs
  // in string replace {{ key }} with value
  // acceptable format: {{key}}, {{ key }}, {{KEY}}, {{ KEY }}, {{   Key }} etc.
  // if a pipe is present inside the curly braces,
  // then the value after the pipe is the default value
  // {{ key | default value }}

  // modify the above to add support for the following functions:
  // 1. {{ key | capitalize }}
  // 2. {{ key | lowercase }}
  // 3. {{ key | uppercase }}
  // 4. {{ key | trim }}
  // 5. {{ key | trimStart }}
  // 6. {{ key | trimEnd }}
  // 7. {{ key | default: "default value" }}
  // 8. {{ key | default: 0 }}
  // 9. {{ key | default: "" }}
  // 10. {{ key | replace: "a", "b" }}

  // allow functions to be chained
  // example:
  // {{ key | trim | lowercase | default: "default value" | replace: "None of the above", "nothing" }}

  if (!string || !variables) return string;

  const regex = /{{\s*([\w-]+)\s*((?:\|\s*[\w-]+\s*(?::\s*[^:|}]+)?)*)\s*}}/g;

  const escapeRegExp = (str) => str.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");

  const executeFunctions = (value, funcs) => {
    let defaultValue = null;

    // Start by setting the value from the variable or setting to empty string
    if (typeof value === "undefined") {
      value = "";
    }

    // String manipulations
    for (const func of funcs) {
      const funcName = func.trim().split(":")[0].trim().toLowerCase();
      switch (funcName) {
        case "trim":
          value = value.trim();
          break;
        case "trimStart":
          value = value.trimStart();
          break;
        case "trimEnd":
          value = value.trimEnd();
          break;
      }
    }

    // Transformations
    for (const func of funcs) {
      const parts = func.trim().split(":");
      const funcName = parts[0]?.trim().toLowerCase();
      switch (funcName) {
        case "capitalize":
          value = value.charAt(0).toUpperCase() + value.slice(1);
          break;
        case "lowercase":
          value = value.toLowerCase();
          break;
        case "uppercase":
          value = value.toUpperCase();
          break;
        case "default":
          defaultValue = parts[1]?.trim().replace(/^"|"$/g, "");
          break;
      }
    }

    // Check for default value after all other operations
    if (value === "" && defaultValue !== null) {
      value = defaultValue;
    }

    // Transformations that require the default value to be set
    for (const func of funcs) {
      const parts = func.trim().split(":");
      const funcName = parts[0]?.trim().toLowerCase();
      switch (funcName) {
        case "replace":
          const [searchValue, replaceValue] = parts[1].split(",").map((str) => str.trim().replace(/^"|"$/g, ""));
          value = value.replace(new RegExp(escapeRegExp(searchValue), "g"), replaceValue);
          break;
      }
    }

    return value;
  };

  return string.replace(regex, (match, variable, funcs) => {
    let value = variables[variable];
    if (funcs) {
      const functions = funcs.trim().split("|").slice(1);
      value = executeFunctions(value, functions);
    }
    return value;
  });
};
