import { AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { Switch, Route, RouteComponentProps } from "react-router-dom";

import { NotFound, Question, Results, Start } from "~/containers";

export const Router = ({ location }: Pick<RouteComponentProps, "location" | "history">) => (
  <AnimateSharedLayout>
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.pathname?.startsWith("/questions/") ? "/questions/" : location.pathname}>
        <Route exact path="/" component={Start} />
        <Route exact path="/questions/:number" component={Question} />
        <Route exact path="/results" component={Results} />
        <Route exact path="/results/:sessionId" component={Results} />
        <Route component={NotFound} />
      </Switch>
    </AnimatePresence>
  </AnimateSharedLayout>
);
