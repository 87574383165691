import { forwardRef, useMemo, ReactNode } from "react";
import { Box, Icon, Spinner, SystemProps } from "@storyofams/react-ui";
import { readableColor, rgba } from "polished";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { borderRadius, ResponsiveValue, variant } from "styled-system";
import { convertCSStoObject } from "~/context/helper";
import { useFlow, useIsRtl } from "~/hooks";
import { ReactComponent as arrowRight } from "../Icon/library/arrow-right.svg";

const _defaultElement = "button";

const sizes = {
  small: {
    borderRadius: "xs",
    py: 2,
    px: 3,
    fontSize: 2,
    lineHeight: "19px",
  },
  large: {
    borderRadius: "md",
    py: 2.5,
    px: 7,
    fontSize: 2.25,
    lineHeight: "22px",
  },
};

const variants = {
  primary: {
    bg: "primary",
    color: "white",
  },
  secondary: {
    bg: "white",
    color: "black90",
  },
};

type ButtonProps = Omit<SystemProps, "size"> & {
  arrow?: boolean;
  children: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  onClick?(e?: any): void;
  /** @default large */
  size?: ResponsiveValue<keyof typeof sizes>;
  to?: string;
  type?: "button" | "submit";
  /** @default primary */
  variant?: ResponsiveValue<keyof typeof variants>;
  className?: string;
  id?: string;
  style?: any;
  textColor?: string;
  backgroundColor?: string;
};

const Hover = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 0;
  transition: background-color 0.18s ease-in-out, box-shadow 0.18s ease-in-out;
`;

const StyledIcon = styled(Icon)`
  display: block;
  overflow: hidden;

  svg {
    width: 100%;
  }
`;

const StyledButton = styled(Box)<
  Pick<ButtonProps, "variant"> & {
    foregroundColor?: string;
    isRtl?: boolean;
    primaryColor?: string;
  }
>`
  position: relative;
  appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: inherit;
  text-align: center;
  text-decoration: none;
  border: 0;
  transition: background-color 0.18s ease-in-out, box-shadow 0.18s, border-color 0.18s ease-in-out, color 0.18s ease-in-out, opacity 0.18s ease-in-out;
  overflow: hidden;
  user-select: none;
  font-weight: bold;
  outline: none;
  margin-bottom: 40px;

  ${StyledIcon} {
    width: 0;
    transition: width 0.25s ease-out, margin 0.25s ease-out;
  }

  ${(p) =>
    p.isRtl &&
    css`
      ${StyledIcon} {
        transform: rotate(180deg);
      }
    `}

  &:hover {
    ${Hover} {
      background-color: ${(p) => p.theme.colors[p.variant === "secondary" ? "black10" : "black20"]};
    }

    ${StyledIcon} {
      width: 22px;
      margin-inline-start: 8px;
    }
  }

  &:active,
  &:focus {
    box-shadow: 0px 0px 0px 3px ${(p) => rgba(p.primaryColor || p.theme.colors.primary, 0.4)};

    ${Hover} {
      background-color: transparent;
    }
  }

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;

    ${Hover} {
      background-color: ${(p) => p.theme.colors.white40};
    }

    &:not([data-is-loading]) {
      background-color: ${(p) => p.theme.colors.black4};
      color: ${(p) => p.theme.colors.black10};

      ${Hover} {
        background-color: transparent;
      }
    }

    ${StyledIcon} {
      width: 0;
    }
  }

  &&[data-is-loading] {
    cursor: wait;
  }

  ${variant({ variants })}
  ${variant({ prop: "size", variants: sizes })}
  ${borderRadius}

  ${(p) =>
    !!p.primaryColor &&
    p.variant === "primary" &&
    css`
      background-color: ${p.primaryColor};
      color: ${p.foregroundColor};
    `}
`;

export const Button = forwardRef(({ arrow, className, textColor, backgroundColor, style = {}, ...props }: ButtonProps, ref) => {
  const { flow } = useFlow();
  const isRtl = useIsRtl();

  const primaryColor = useMemo(() => backgroundColor ?? flow?.primaryColor, [flow?.primaryColor, backgroundColor]);

  const foregroundColor = textColor ? textColor : primaryColor ? readableColor(primaryColor, "rgba(0, 0, 0, 0.9)", "#fff") : "white";

  const content = (
    <StyledButton
      as={_defaultElement}
      variant="primary"
      size="large"
      foregroundColor={foregroundColor}
      primaryColor={primaryColor as string}
      className={`button ${className ?? ""}`}
      style={{ ...convertCSStoObject(flow?.cssEditor)[".buttons"], ...style }}
      {...props}
      {...(props?.isLoading
        ? {
            "data-is-loading": true,
            "aria-disabled": true,
            disabled: true,
          }
        : {})}
      isRtl={isRtl}
      /** @ts-ignore */
      ref={ref}
    >
      <Hover />
      <Box display="flex" zIndex={1}>
        <>
          {props.children}
          {props?.isLoading ? (
            <div style={convertCSStoObject(flow?.cssEditor)[".loading"]}>
              <Spinner
                size={20}
                css={{
                  marginInlineStart: "10px",
                }}
              />
            </div>
          ) : (
            <>{!!arrow && <StyledIcon icon={arrowRight} />}</>
          )}
        </>
      </Box>
    </StyledButton>
  );

  if (props?.to) {
    return <Link to={props.to}>{content}</Link>;
  }

  return content;
});
